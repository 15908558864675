import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { styles } from "./ScannerStyles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import QrReader from "react-qr-reader";
import {
  Box,
  Grid,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { apiData } from "../../../../common/common-types";
import { stockAPI } from "../../../../common/axiosInstance";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";
import { decrypt, encrypt } from "../../../../crypto-helper";
import moment from "moment";

class Scanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: "No result",
      isScanSuccess: false,
      otpSent: false,
      otp: "",
      otpId: "",
      timer: 120, // 2 minutes = 120 seconds
      isResendDisabled: true,
      openOverrideModal: false, // Modal open state
      isScanning: false, // Prevent multiple scan triggers
      productDetails: null,
    };

    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this);
    this.resetOtpTimer = this.resetOtpTimer.bind(this);
  }

  componentDidMount() {
    // Get the URL parameters

    let { customerId, productId, url } = this?.props?.urlDetails || {};
    if (url && customerId) {
      // Call handleScan if the id is present
      this.handleScan(this?.props?.urlDetails);
    }
  }

  resetState = () => {
    this.setState({
      result: "No result",
      isScanSuccess: false,
      otpSent: false,
      otp: "",
      otpId: "",
      timer: 120,
      isResendDisabled: true,
      openOverrideModal: false,
      isScanning: false,
      productDetails: null,
    });
  };

  // Handle scanning logic
  async handleScan(data) {
    if (!data || this.state.isScanning) return; // Prevent multiple scans

    this.setState({ isScanning: true }); // Set scanning in progress

    try {
      const urlParams =
        typeof data === "string"
          ? new URLSearchParams(data?.split("?")[1])
          : "";
      const paramsObject =
        typeof data === "string"
          ? Object.fromEntries(urlParams.entries())
          : { ...data };

      if (paramsObject?.mobileNo && paramsObject?.productId) {
        let selectedProDetails = null;
        this.setState({
          ...this.state,
          ...paramsObject,
        });

        const formDataProductDetails = new URLSearchParams();
        formDataProductDetails.append(
          "product_merchant_id",
          paramsObject?.productId
        );
        formDataProductDetails.append("product_id", 0);
        formDataProductDetails.append("customer_id", paramsObject?.customerId);
        formDataProductDetails.append("merchant_id", apiData.merchant_id);
        formDataProductDetails.append("platform", apiData.pv_platform);

        // Get product details API call
        const productDetails = await stockAPI(
          formDataProductDetails,
          "POST",
          "/getProductDetails",
          null,
          null,
          undefined,
          true
        );

        if (
          productDetails?.response?.productDetails?.length &&
          productDetails?.statusCode === 100
        ) {
          selectedProDetails = productDetails?.response?.productDetails?.filter(
            (x) => x.id === paramsObject?.productId
          );
        } else {
          this.setState({ isScanning: false });
          this.props.history.push("/pv-notify", {
            isSuccess: false,
            // customerName: "sonal",
            reason: "Failed to retrieve product details.",
          });
        }

        if (selectedProDetails) {
          this.setState({
            ...this.state,
            productDetails: selectedProDetails?.[0],
          });

          let finalCartArray = selectedProDetails?.map((item) => ({
            ...item,
            qty: 1,
          }));

          const bodyFormData = new URLSearchParams();
          bodyFormData.append("customer_id", paramsObject?.customerId);
          bodyFormData.append("merchant_id", apiData.merchant_id);
          bodyFormData.append("platform", apiData.pv_platform);
          bodyFormData.append("cart_details", JSON.stringify(finalCartArray));

          // Add to cart
          const addToCart = await stockAPI(
            bodyFormData,
            "POST",
            "/addCart",
            null,
            null,
            undefined,
            true
          );

          if (addToCart?.statusCode === 100) {
            this.placeOrder();
          } else {
            this.setState({ isScanning: false });

            this.props.history.push("/pv-notify", {
              isSuccess: false,
              // customerName: "sonal",
              reason: "error while placing order",
            });
          }
        }
      } else {
        this.setState({ isScanning: false });

        this.props.history.push("/pv-notify", {
          isSuccess: false,
          // customerName: "sonal",
          reason: "Please scan valid QR code",
        });
      }
    } catch (err) {
      this.setState({ isScanning: false });
      openAlertBox(err.message || "Oops, something went wrong!", "error");
    } finally {
      // this.setState({ isScanning: false }); // Reset scanning flag
    }
  }

  handleError(err) {
    console.error(err);
    openAlertBox("Error during QR scan: " + err.message, "error");
    this.props.history.push("/pv-notify", {
      isSuccess: false,
      // customerName: "sonal",
      reason: "Error during QR scan: " + err.message,
    });
  }

  // Reset OTP timer and prevent resend for the duration
  resetOtpTimer() {
    this.setState({ timer: 120, isResendDisabled: true });
    this.otpTimer = setInterval(() => {
      if (this.state.timer > 0) {
        this.setState((prevState) => ({ timer: prevState.timer - 1 }));
      } else {
        clearInterval(this.otpTimer);
        this.setState({ isResendDisabled: false });
      }
    }, 1000);
  }

  handleResendotp() {
    const formData = {
      mobile_number: this?.state?.mobileNo?.split(" ")?.join(""),
      platform: apiData.platform,
      transactionType: 201,
      merchant_id: apiData.merchant_id,
    };
    let encryptedPayload = {
      _k: encrypt(JSON.stringify(formData)),
    };

    stockAPI(encryptedPayload, "POST", "/getOtp", null, null, null, true)
      .then(async (res) => {
        // if success
        this.props.showLoader(false);
        if (res && res.statusCode === 100) {
          openAlertBox("OTP sent successfully!", "success");
          this.setState({
            ...this.state,
            isScanSuccess: true,
            otpSent: true,
            otpId: res?.response?.id,
          });
          this.resetOtpTimer();
        } else if (
          res?.statusCode === 102 &&
          res?.statusDescription?.toLowerCase() === "otp already sent."
        ) {
          this.setState({
            ...this.state,
            isScanSuccess: true,
            otpSent: true,
          });
          this.props.showLoader(false);
          openAlertBox(
            res?.statusDescription || "Oops something went wrong!",
            "error"
          );
        } else {
          this.props.showLoader(false);
          openAlertBox("Error sending OTP", "error");
        }
      })
      .catch(function (err) {
        this.props.showLoader(false);
        openAlertBox(err && err.message, "error");
      });
  }

  // Open modal for OTP override
  handleOpenModal = () => {
    this.setState({ openOverrideModal: true });
  };

  // Close modal
  handleCloseModal = () => {
    this.setState({ openOverrideModal: false });
  };

  // Handle modal submit (override OTP logic)
  handleModalSubmit = () => {
    // Logic for overriding OTP goes here
    this.placeOrder();
    openAlertBox("OTP verification overridden!", "success");
    this.handleCloseModal(); // Close the modal after submission
  };

  placeOrder = () => {
    const data = new URLSearchParams();
    let placeOrderArr = [{ ...this.state?.productDetails }];
    let orderArr = [];
    placeOrderArr.map((product) => {
      orderArr.push({
        product_merchant_id: product.id,
        qty: 1,
        location_id: product.location_id,
        best_price: 0,
        calculated_points: 0,
      });
    });
    data.append("kiosk_user_id", this?.props?.pvUserDetails?.id);
    data.append("customer_id", this?.state?.customerId);
    data.append("merchant_id", apiData.merchant_id);
    data.append("platform", apiData.pv_platform);
    data.append("order_details", JSON.stringify(orderArr));
    stockAPI(data, "POST", "/placeOrder", null, null, undefined, true)
      .then(async (response) => {
        if (response.response && response.statusCode === 100) {
          openAlertBox(
            response.statusDescription ||
              "Your order has been placed successfully!",
            "success"
          );
          this.setState({ isScanning: false });

          this.props.history.push("/pv-notify", {
            isSuccess: true,
            customerName: response?.response?.customer_name || "",
            scannedAt: response?.response?.order_timestamp
              ? moment(response?.response?.order_timestamp)?.format(
                  "DD MMM YYYY | h:mma"
                )
              : "-",
            privilegeDetails:
              response?.response?.order_privilege_product_details?.[0] || null,
          });
          setTimeout(() => {
            this.resetState();
          }, 7000);
        } else {
          this.setState({ isScanning: false });
          this.props.history.push("/pv-notify", {
            isSuccess: false,
            customerName: response?.response?.customer_name,
            reason: response?.statusDescription || "error while placing order",
          });
          setTimeout(() => {
            this.resetState();
          }, 7000);
          // openAlertBox("error while placing order", "error");
        }
      })
      .catch((err) => {
        this.setState({ isScanning: false });

        setTimeout(() => {
          this.resetState();
        }, 7000);
        this.props.history.push("/pv-notify", {
          isSuccess: false,
          customerName: err?.response?.customer_name,
          reason: err?.statusDescription || "error while placing order",
        });
        // openAlertBox("error while placing order", "error");
      });
  };
  handleCancel = () => {
    this.resetState();
  };

  handleResetOtp = () => {
    this.handleResendotp();
    this.resetOtpTimer();
  };

  render() {
    const { classes } = this.props;
    const { isResendDisabled, timer, openOverrideModal } = this.state;

    return (
      <div className={classes.redeemCnt}>
        {/* {!this.state?.isScanSuccess ? ( */}
        <div className={classes.wrapper}>
          <Typography variant="h1" className={classes.heading}>
            QR Code Reader
          </Typography>
          <Typography
            variant="h3"
            className={classes.subHeading}
            style={{ marginBottom: "20px", marginTop: "10px" }}
          >
            Scan your QR code here
          </Typography>
          <div
            // className={classes.formWrap}
            style={{ margin: "20px auto", width: "70%", height: "200px" }}
          >
            <QrReader
              delay={300}
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: "100%" }}
              facingMode="environment"
            />
          </div>
        </div>
        {/* ) : (
            <Box p={3}>
              <Typography
                variant="h1"
                className={classes.heading}
                style={{ marginBottom: "20px" }}
              >
                Verify Customer
              </Typography>

              <Typography
                variant="h3"
                className={classes.prodName}
                style={{ marginBottom: "20px" }}
              >
                {this.state.productDetails.sub_product_name
                  ? `Privilege voucher: ${this.state.productDetails.sub_product_name}`
                  : null}
              </Typography>

              <Grid item xs={12} md={12} alignItems="center">
                <TextField
                  label="Enter OTP"
                  variant="outlined"
                  required
                  fullWidth
                  value={this.state.otp}
                  onChange={(e) => this.setState({ otp: e.target.value })}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                style={{
                  textAlign: "right",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                <Link
                  href="#"
                  color="primary"
                  onClick={this.handleResetOtp}
                  disabled={isResendDisabled}
                  style={{
                    pointerEvents: isResendDisabled ? "none" : "auto",
                    color: isResendDisabled ? "grey" : "blue",
                  }}
                >
                  Resend OTP {isResendDisabled ? `(${timer}s)` : ""}
                </Link>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.submitOtp}
                    onClick={this.verifyOtp}
                  >
                    Submit
                  </Button>
                </Grid>

                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    className={classes.overrideOtp}
                    onClick={this.handleOpenModal} // Open the modal when clicked
                  >
                    Override OTP
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.cancelBtn}
                    onClick={this.handleCancel} // Open the modal when clicked
                  >
                    cancel
                  </Button>
                </Grid>
              </Grid>

              <Dialog open={openOverrideModal} onClose={this.handleCloseModal}>
                <DialogTitle>{"Override OTP Verification"}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Do you really want to skip/override the OTP verification
                    process? The management will be informed immediately
                    regarding the same.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.handleCloseModal}
                    variant="contained"
                    color="primary"
                    className={classes.cancelModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={this.handleModalSubmit}
                    variant="contained"
                    color="secondary"
                    className={classes.submitModal}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          )} */}
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(Scanner));
