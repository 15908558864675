import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setOTPVerifiedFlag } from "../OtpVerification/redux/action";
import { showLoader } from "../../../App/redux/action";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import { setRegVisitedflag } from "../OtpVerification/redux/action";
import HeaderPvDashboard from "../../../components/Layout/Header/HeaderPvDashboard";
import { logout_pv_user, url_details } from "../PV/redux/action";
import PvFooter from "../../../components/Layout/Footer/PvFooter";
import Footer from "../../../components/Layout/Footer/Footer";

import { Grid } from "@material-ui/core";
import MyRoute from "../../../hoc/MyRoute";
import PastScannedList from "./components/PastScannedList";

const mapDispatchToProps = {
  setOTPVerifiedFlag,
  showLoader,
  setRegVisitedflag,
  logout_pv_user,
  url_details,
};
const mapStateToProps = (state) => {
  return {
    isAuthenticatedPvUser:
      state?.reducerPvUserAuthentication?.isAuthenticatedPvUser,
    pvUserDetails: state?.reducerPvUserAuthentication?.pvUserDetails,
  };
};

class PvPastScannedContainer extends Component {
  componentDidMount() {
    const { setOTPVerifiedFlag } = this.props;
    setOTPVerifiedFlag(false);
  }
  render() {
    const { showLoader, setRegVisitedflag, logout_pv_user, pvUserDetails } =
      this.props;

    return (
      <Fragment>
        <HeaderPvDashboard
          logout_pv_user={logout_pv_user}
          pvUserDetails={pvUserDetails}
        />
        <AlertMessage />
        <MyRoute dashboard={true} billUploadStatus={this.props.uploadingStatus}>
          <PastScannedList
            showLoader={showLoader}
            setRegVisitedflag={setRegVisitedflag}
            pvUserDetails={pvUserDetails}
          />
        </MyRoute>
        <Grid item>
          <PvFooter />
        </Grid>
      </Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PvPastScannedContainer));
