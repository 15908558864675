export const styles = (theme) => {
  return {
    square: {
      width: "100%",
      height: "53px",
      padding: "10px",
      color: "#000000",
      backgroundColor: theme.palette.tier.gold,
      float: "left",
      textAlign: "center",
      fontWeight: "bold",
      [theme.breakpoints.down("lg")]: {
        borderRadius: "4px",
      },
      [theme.breakpoints.down("sm")]: {
        borderRadius: "4px",
      },
      [theme.breakpoints.down("xs")]: {
        borderRadius: "4px",
        height: "48px",
      },
      fontSize: "18px",
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
        height: "37px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
        height: "28px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "9px",
        height: "25px",
      },
    },
    tierAvatarRoot: {
      [theme.breakpoints.down("lg")]: {
        Width: "50px",
        Height: "35px",
      },
      [theme.breakpoints.down("sm")]: {
        Width: "50px",
        Height: "35px",
      },
      [theme.breakpoints.down("xs")]: {
        Width: "22px",
        Height: "15px",
      },
    },
    down_icon: {
      position: "absolute",
      pointerEvents: "none",
      right: "20px",
      top: "calc(50% - 5px)",
    },
    formControl: {
      "& .MuiInputBase-input:focus": {
        backgroundColor: "unset",
      },
    },
    menuItem: {
      fontSize: "18px",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    voucher_container: {
      paddingBottom: "15px",
    },
    coupons_box: {
      width: "auto",
      minWidth: "90px",
      marginBottom: "2px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      float: "right",
      // borderRadius: "4px",
      border: "dashed 2px #a7da2b",
      cursor: "pointer",
      height: "100%",
      padding: "10px 10px",
      [theme.breakpoints.down(421)]: {
        padding: "5px 8px",
      },
      // [theme.breakpoints.down(381)]: {
      //   padding: "5px 7px",
      // },
      [theme.breakpoints.down(321)]: {
        padding: "4px 7px",
      },
    },
    coupons: {
      color: theme.palette.typography.texts.color,
      textAlign: "center",
      fontSize: "22px",
      fontWeight: "600",
      // padding: "3px 0px",
      [theme.breakpoints.down("641")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "17.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "16.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "16px",
      },
    },
    flexCol: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      float: "right",
    },
    dateValidity: {
      fontFamily: "Montserrat",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontSize: "16px",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "9px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "8.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "8px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "6px",
      },
    },
    voucher_pin_box: {
      marginBottom: "4px",
      padding: "10px 10px 10px 24px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      border: "dashed 2px #a7da2b",
      cursor: "pointer",
      height: "100%",
      [theme.breakpoints.down(481)]: {
        marginLeft: "13%",
      },
      [theme.breakpoints.down(421)]: {
        padding: "3px 3px 3px 14px",
        marginLeft: "15%",
      },
      [theme.breakpoints.down(381)]: {
        marginLeft: "13.5%",
      },
      [theme.breakpoints.down(321)]: {
        padding: "2px 2px 2px 12px",
      },
      "& .MuiTypography-body1": {
        lineHeight: 1.3,
      },
    },
    voucherPin: {
      fontFamily: "Montserrat",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontSize: "18px",
      color: "#fff",
      padding: "6px 10px",
      backgroundColor: "#A8DA2A",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    voucher_content: {
      display: "flex",
      flexDirection: "column",
      width: "96%",
    },
    listVoucher: {
      width: "100%",
      float: "left",
      textAlign: "center",
      fontWeight: "400",
      fontSize: "16px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    voucherCode: {
      width: "100%",
      float: "left",
      textAlign: "center",
      fontWeight: "500",
      fontSize: "18px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    pin: {
      padding: 0,
      margin: 0,
      textAlign: "left",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    emptyDiv: {
      display: "block",
      padding: "18px",
    },
  };
};
