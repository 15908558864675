import * as actionTypes from "./actionTypes";
import { getStorageItem } from "../../common/localStorage";
import * as actionTypesOtp from "../../containers/Screens/OtpVerification/redux/actionTypes";
import * as actionTypesPv from "../../containers/Screens/PV/redux/actionTypes";

import { logout, logoutPv } from "../../common/commonFunctions";
import { RESET_OFFERS } from "../../containers/Screens/Offers/redux/actionTypes";
import {
  RESET_REDEEM,
  RESET_REDEEM_DATA,
} from "../../containers/Screens/Redeem/redux/actionTypes";

import { RESET_O_VERIFICATION } from "../../containers/Screens/OtpVerification/redux/actionTypes";
import { RESET_DASHBOARD } from "../../containers/Screens/Dashboard/redux/actionTypes";
import { RESET_FILTERS } from "../../components/Filter/redux/actionTypes";
import {
  SEARCH_TEXT_RESET,
  SEARCH_OFFER_TEXT_RESET,
} from "../../components/Search/redux/actionTypes";
import { CLEAR_BRANDS } from "../../containers/Screens/More/components/StoreLocator/redux/actionTypes";
import { store } from "../../store";

const changeActiveTab = (id) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CHANGE_TAB, tab: id });
  };
};

const onTryAutoLogin = () => {
  return async (dispatch) => {
    if (
      getStorageItem("user") &&
      getStorageItem("currencyCode") &&
      getStorageItem("pointShortName") &&
      getStorageItem("loyaltyId")
    ) {
      dispatch({ type: actionTypesOtp.AUTH_USER, payload: true });
    } else {
      logoutUser();
    }
  };
};

const logoutUser = () => {
  return async (dispatch) => {
    await logout();
    dispatch({ type: actionTypesOtp.AUTH_USER, payload: false });

    dispatch({ type: RESET_REDEEM });
    dispatch({ type: RESET_REDEEM_DATA });

    dispatch({ type: RESET_OFFERS });
    dispatch({ type: RESET_O_VERIFICATION });
    dispatch({ type: RESET_DASHBOARD });
    dispatch({ type: RESET_FILTERS });
    dispatch({ type: SEARCH_TEXT_RESET });
    dispatch({ type: SEARCH_OFFER_TEXT_RESET });
    dispatch({ type: CLEAR_BRANDS });
  };
};
const logoutPvUser = () => {
  return async (dispatch) => {
    await logoutPv();
     dispatch({ type: actionTypesPv.lOGOUT_PV_USER, payload: false });

    // dispatch({ type: RESET_REDEEM });
    // dispatch({ type: RESET_REDEEM_DATA });

    // dispatch({ type: RESET_OFFERS });
    // dispatch({ type: RESET_O_VERIFICATION });
    // dispatch({ type: RESET_DASHBOARD });
    // dispatch({ type: RESET_FILTERS });
    // dispatch({ type: SEARCH_TEXT_RESET });
    // dispatch({ type: SEARCH_OFFER_TEXT_RESET });
    // dispatch({ type: CLEAR_BRANDS });
  };
};

const showLoader = (loader) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SHOW_LOADER, loader });
  };
};

const authUser = (val) => {
  return (dispatch) => {
    dispatch({ type: actionTypesOtp.AUTH_USER, payload: val });
  };
};

export { changeActiveTab, onTryAutoLogin, logoutUser, showLoader, authUser, logoutPvUser };
