import { combineReducers } from "redux";
import { encryptTransform } from "redux-persist-transform-encrypt";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";
import reducerRegistration from "./containers/Screens/Registration/redux/reducerRegistration";
import reducerDashboard from "./containers/Screens/Dashboard/redux/reducerDashboard";
import reducerOtpverification from "./containers/Screens/OtpVerification/redux/reducerOtpverification";
import reducerRedeem from "./containers/Screens/Redeem/redux/reducerRedeem";
import reducerMyCart from "./containers/Screens/MyCart/redux/reducerMyCart";
import reducerProfile from "./containers/Screens/Profile/redux/Reducer";
import reducerApp from "./App/redux/reducerApp";
import reducerFilter from "./components/Filter/redux/reducerFilter";
import reducerProductView from "./containers/Screens/Redeem/redux/reducer";
import reducerSearch from "./components/Search/redux/reducerSearch";
import reducerSubmitBill from "./containers/Screens/SubmitBill/redux/reducerSubmitBill";
import reducerOffers from "./containers/Screens/Offers/redux/reducer";
import reducerBrand from "./containers/Screens/More/components/StoreLocator/redux/reducer";
import reducerBillUploadProgress from "./containers/Screens/BillUploadProgress/redux/reducerBillUploadProgress";
import reducerTransactions from "./containers/Screens/Transactions/redux/reducerTransactions";
import reducerBillUploadGuidelines from "./containers/Screens/BillUploadGuidelines/redux/reducerBillUploadGuidelines";
import reducerNotifications from "./containers/Screens/Notifications/redux/reducerNotifications";
import reducerPvUserAuthentication from "./containers/Screens/PV/redux/reducerPvUserAuthentication"

const encryptor = encryptTransform({
  secretKey: "stickar-secret-key",
  onError: function (error) {
    // Handle the error.
    console.log("ERROR IN REDUX PERSIST", error);
  },
});

const persistConfig = {
  key: "root",
  storage,
  transforms: [encryptor],
  stateReconciler: autoMergeLevel2,
  whitelist: [
    "reducerFilter",
    "reducerProductView",
    "reducerSearch",
    "reducerOtpverification",
    "reducerDashboard",
    "reducerBrand",
    "reducerSubmitBill",
    "reducerPvUserAuthentication"
  ],
};

const rootReducer = {
  reducerRegistration,
  reducerDashboard,
  reducerOtpverification,
  reducerRedeem,
  reducerMyCart,
  reducerApp,
  reducerFilter,
  reducerProductView,
  reducerSearch,
  reducerSubmitBill,
  reducerOffers,
  reducerProfile,
  reducerBillUploadProgress,
  reducerTransactions,
  reducerBrand,
  reducerBillUploadGuidelines,
  reducerNotifications,
  reducerPvUserAuthentication,
};

const combinedReducer = combineReducers(rootReducer);

export default persistReducer(persistConfig, combinedReducer);
