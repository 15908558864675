import selected from "../../../../assets/icons/book_visit/selected.svg";
import nonselected from "../../../../assets/icons/book_visit/disable-checkbox.svg";

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const filterStyles = (theme) => {
  return {
    filter_root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "540px",
      overflow: "hidden",
      [theme.breakpoints.down("xs")]: {
        width: "500px",
      },
      [theme.breakpoints.down("550")]: {
        width: "450px",
      },
      [theme.breakpoints.down("500")]: {
        width: "400px",
      },
      [theme.breakpoints.down("450")]: {
        width: "350px",
      },
      [theme.breakpoints.down("400")]: {
        width: "300px",
      },
      [theme.breakpoints.down("350")]: {
        width: "250px",
      },
      "& $filter_header_label_container": {
        width: "100%",
        padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
        [theme.breakpoints.down("sm")]: {
          padding: "20px 18px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "18px 15px",
        },
        [theme.breakpoints.down("480")]: {
          padding: "12px 15px",
        },
        [theme.breakpoints.between(360,380)]: {
          padding: "8px 15px",
        },
        [theme.breakpoints.down("321")]: {
          padding: "8px 15px",
        },
        ["@media(max-height: 600px)"]: {
          padding: "8px 15px",
        },
        "& $filter_header": {
          fontSize: "22px",
          fontWeight: 500,
          [theme.breakpoints.down(641)]: {
            fontSize: "20px",
          },
          [theme.breakpoints.down(481)]: {
            fontSize: "18px",
          },
          [theme.breakpoints.down(421)]: {
            fontSize: "17.5px",
          },
          [theme.breakpoints.down(401)]: {
            fontSize: "17px",
          },
          [theme.breakpoints.down(381)]: {
            fontSize: "16px",
          },
          [theme.breakpoints.down(361)]: {
            fontSize: "16px",
          },
          [theme.breakpoints.down(321)]: {
            fontSize: "14px",
          },
        },
      },
      "& $filter_label": {
        fontSize: "20px",
        fontWeight: 500,
        [theme.breakpoints.down(641)]: {
          fontSize: "18px",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "15.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "15px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "14.5px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "12px",
        },
      },
      "& $filter_font_color_primary": {
        color: theme.palette.typography.texts.color,
      },
      "& $filter_item": {
        width: "100%",
        borderTop: "1px solid #e4e4e4",
      },
      "& $filter_label_container": {
        padding: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
          padding: "20px 18px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "18px 15px",
        },
        [theme.breakpoints.down("480")]: {
          padding: "12px 15px",
        },
        [theme.breakpoints.between(360, 380)]: {
          padding: "8px 15px",
        },
        [theme.breakpoints.down("321")]: {
          padding: "8px 15px",
        },
        ["@media(max-height: 600px)"]: {
          padding: "8px 15px",
        },
      },
      "& $form_item": {
        width: "100%",
        padding: `0 ${theme.spacing(2)}px`,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
          padding: `0 18px`,
          marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down("xs")]: {
          padding: `0 15px`,
          marginBottom: theme.spacing(2),
        },
        // [theme.breakpoints.down("480")]: {
        //   marginBottom: theme.spacing(2),
        // },
        [theme.breakpoints.down("321")]: {
          marginBottom: "10px",
        },
      },
    },
    tx_label: {
      padding: "20px 0 0px 24px",
      fontSize: "18px",
      color: "#aaaaaa",
      display: "block",
      textAlign: "left",
      [theme.breakpoints.down(641)]: {
        fontSize: "14px",
        padding: "16px 0 0px 24px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "12px",
        padding: "10px 0 0px 16px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "11.5px",
        padding: "10px 0 0px 16px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "10.5px",
        padding: "10px 0 0px 16px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
        padding: "6px 0 0px 16px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
        padding: "5px 0 0px 16px",
      },
    },
    paper: {
      width: "100%",
      border: "solid 1px #e4e4e4",
      borderRadius: "4px",
      boxShadow: "none",
      overflow: "hidden",
      height: "92px",
      [theme.breakpoints.down(641)]: {
        height: "80px",
      },
      [theme.breakpoints.down(481)]: { height: "54px" },
      [theme.breakpoints.down(381)]: { height: "46px" },
      [theme.breakpoints.down(321)]: { height: "40px" },
    },
    down_icon: {
      position: "absolute",
      pointerEvents: "none",
      right: "16px",
      top: "calc(50%)",
      [theme.breakpoints.down("xs")]: {
        top: "calc(50%)",
      },
    },
    range_container: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: `0 ${theme.spacing(4.4)}px 0 ${theme.spacing(3.9)}px`,
      marginTop: `15px`,
      overflow: "hidden",
      "& .MuiSlider-mark": {
        width: 0,
      },
      [theme.breakpoints.between(360, 380)]: {
        padding: `0 ${theme.spacing(4.4)}px 0 ${theme.spacing(4.1)}px`,
      },
      [theme.breakpoints.down(321)]: {
        marginTop: `${theme.spacing(1)}px`,
        "& .MuiSlider-marked": {
          marginBottom: "10px",
        },
      },
      ["@media(max-height: 600px)"]: {
        marginTop: `${theme.spacing(1)}px`,
        "& .MuiSlider-marked": {
          marginBottom: "10px",
        },
      },
    },
    inner_btn_container: {
      padding: "30px 30px 30px 30px",
      [theme.breakpoints.down("sm")]: {
        padding: "15px 15px 15px 15px",
      },
      ["@media(max-height: 600px)"]: {
        padding: "10px 15px",
      }
    },
    button_container: {
      // paddingBottom: "30px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // [theme.breakpoints.down("sm")]: {
      //   paddingBottom: "15px",
      // },
      [theme.breakpoints.down(421)]: {
        marginTop: iOS() ? 8 : 0,
      },
      "& button": {
        ...theme.palette.typography.doneButtons,
        marginBottom: 0,
      },
      "& $applyBtn": {
        width: "50%",
        marginRight: "5px",
        color: "#fff",
        maxWidth: "250px",
        fontSize: "22px",
        lineHeight: "18px",
        marginTop: "15px",
        height: "92px",
        [theme.breakpoints.down(641)]: {
          height: "80px",
          fontSize: "16px",
          lineHeight: "16px",
        },
        [theme.breakpoints.down(481)]: {
          height: "54px",
          fontSize: "14px",
          lineHeight: "12px",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "13.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "13px",
        },
        [theme.breakpoints.down(381)]: {
          height: "46px",
          fontSize: "12.5px",
          lineHeight: "9px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down(321)]: {
          height: "40px",
          fontSize: "10px",
          lineHeight: "8px",
        },
      },
      "& $resetBtn": {
        width: "50%",
        marginLeft: "5px",
        maxWidth: "250px",
        fontSize: "22px",
        lineHeight: "18px",
        marginTop: "15px",
        height: "92px",
        backgroundColor: theme.palette.typography.cancelButtons.backgroundColor,
        color: theme.palette.typography.texts.color,
        [theme.breakpoints.down(641)]: {
          height: "80px",
          fontSize: "16px",
          lineHeight: "16px",
        },
        [theme.breakpoints.down(481)]: {
          height: "54px",
          fontSize: "14px",
          lineHeight: "12px",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "13.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "13px",
        },
        [theme.breakpoints.down(381)]: {
          height: "46px",
          fontSize: "12.5px",
          lineHeight: "9px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down(321)]: {
          height: "40px",
          fontSize: "10px",
          lineHeight: "8px",
        },
      },
    },
    filter_header_label_container: {},
    filter_label_container: {},
    filter_label: {},
    filter_font_color_primary: {},
    filter_header: {},
    filter_item: {},
    form_item: {},
    applyBtn: {},
    resetBtn: {},
    // checkbox: {
		// 	width: 40,
		// 	// marginBottom: 24,
		// 	// marginTop: 13,
		// 	// "--size ": "70px",
		// 	// display: "inline-block",
		// 	// transition: ".12s",
		// 	// "-webkit-mask-size": "cover",
		// 	// "mask-size ": "cover",
		// 	// objectFit: "contain",
		// 	[theme.breakpoints.down(641)]: {
		// 		width: 40,
		// 		// marginBottom: 24,
		// 		// marginTop: 13,
		// 	},
		// 	[theme.breakpoints.down(481)]: {
		// 		width: 28,
		// 		// marginBottom: 16,
		// 		// marginTop: 8,
		// 	},
		// 	[theme.breakpoints.down(421)]: {
		// 		width: 28,
		// 		// marginBottom: 16,
		// 		// marginTop: 8,
		// 	},
		// 	[theme.breakpoints.down(401)]: {
		// 		width: 27,
		// 		// marginBottom: 15,
		// 		// marginTop: 8,
		// 	},
		// 	[theme.breakpoints.down(381)]: {
		// 		width: 21,
		// 		// marginBottom: 12,
		// 		// marginTop: 5,
		// 	},
		// 	[theme.breakpoints.down(361)]: {
		// 		width: 24,
		// 		// marginBottom: 12,
		// 		// marginTop: 5,
		// 	},
		// 	[theme.breakpoints.down(321)]: {
		// 		width: 22,
		// 		// marginBottom: 10,
		// 		// marginTop: 4,
		// 	},
    // },
    checkbox: {
      display: "inline-block",
      width: "40px",
      height: "40px",
      cursor: "pointer",
      [theme.breakpoints.down(481)]: {
        width: "28px",
        height: "28px",
      },
      [theme.breakpoints.down(401)]: {
        width: "27px",
        height: "27px",
      },
      [theme.breakpoints.down(381)]: {
        width: "21px",
        height: "21px",
      },
    },
    checkboxText: {
			fontSize: 20,
			// marginTop: 18,
			marginLeft: 20,
			[theme.breakpoints.down(641)]: {
				fontSize: 18,
				// marginTop: 19,
				marginLeft: 20,
			},
			[theme.breakpoints.down(481)]: {
				fontSize: 14,
				// marginTop: 12,
				marginLeft: 20,
			},
			[theme.breakpoints.down(421)]: {
				fontSize: 13,
				// marginTop: 12,
				marginLeft: 20,
			},
			[theme.breakpoints.down(401)]: {
				fontSize: 12,
				// marginTop: 12,
				marginLeft: 20,
			},
			[theme.breakpoints.down(381)]: {
				fontSize: 12,
				// marginTop: 7,
				marginLeft: 20,
			},
			[theme.breakpoints.down(361)]: {
				fontSize: 12,
				// marginTop: 8,
				marginLeft: 20,
			},
			[theme.breakpoints.down(321)]: {
				fontSize: 11,
				// marginTop: 6,
				marginLeft: 20,
			},
		},
    formControl: {
      "& :first-child": {
        paddingRight: "10px",
      },
      "& .MuiInputBase-input:focus": {
        backgroundColor: "unset",
      },
      "& .MuiSelect-select:focus": {
				background: "#fff",
      },
      "&. MuiListItem-root.Mui-selected": {
        background: "#fff",
      }, 
      "& .MuiSelect-selectMenu": {
        padding: 0,
        fontSize: "20px",
        [theme.breakpoints.down(641)]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "13.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "13px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "12.5px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "10px",
        },
      },
    },
    wrapper: {
      minHeight: "calc(100% - 137px)",
      width: "100%",
      [theme.breakpoints.down(641)]: {
        minHeight: "calc(100% - 125px)",
      },
      [theme.breakpoints.down(481)]: {
        minHeight: "calc(100% - 100px)",
      },
      [theme.breakpoints.down(421)]: {
        minHeight: "calc(100% - 99px)",
      },
      // [theme.breakpoints.down(381)]: {
      //   minHeight: "calc(100% - 91px)",
      // },
      [theme.breakpoints.down(321)]: {
        minHeight: "calc(100% - 75px)",
      },
    },
    MenuProps: {
      PaperProps: {
        style: {
          maxHeight: 48 * 4.5 + 8,
          width: "100%",
        },
      },
    },
    MenuItem: {
      [theme.breakpoints.down(641)]: {
        marginBottom: 4,
      },
      [theme.breakpoints.down(481)]: {

        marginBottom: -6,
    
      },
      [theme.breakpoints.down(421)]: {
 
        marginBottom: -6,

      },
      [theme.breakpoints.down(401)]: {

        marginBottom: -11,
        
      },
      [theme.breakpoints.down(381)]: {
     
        marginBottom: -16,
 
      },
      [theme.breakpoints.down(361)]: {
 
        marginBottom: -12,

      },
      [theme.breakpoints.down(321)]: {
  
        marginBottom: -16,
     
      },
    },
    MenuItemDis: {
      marginBottom: 8,
      fontSize: 21,
      [theme.breakpoints.down(641)]: {
        marginBottom: 6,
        paddingBottom: 9,
        fontSize: 19,
      },
      [theme.breakpoints.down(481)]: {
        marginBottom: -10,
        fontSize: 16,
        paddingBottom: 16,
      },
      [theme.breakpoints.down(421)]: {
 
        marginBottom: -20,
        paddingBottom: 24,
        fontSize: 14,

      },
      [theme.breakpoints.down(401)]: {
        marginBottom: -14,
        paddingBottom: 22,
        fontSize: 13,
        
      },
      [theme.breakpoints.down(381)]: {
     
        marginBottom: -20,
        paddingBottom: 26,
        fontSize: 13,
 
      },
      [theme.breakpoints.down(361)]: {
        marginBottom: -12,
        paddingBottom: 20,
        fontSize: 13,

      },
      [theme.breakpoints.down(321)]: {
        fontSize: 12,
        marginBottom: -20,
        paddingBottom: 25,
     
      },
    },
    selected: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${selected})`,
      "mask-image": `url(.${selected})`,
      mask: `url(.${selected}) no-repeat center / contain`,
      "-webkit-mask": `url(.${selected}) no-repeat center / contain`,
    },
    disable_checkbox: {
      backgroundColor: "#b6babd",
      " -webkit-mask-image": `url(.${nonselected})`,
      "mask-image": `url(.${nonselected})`,
      mask: `url(.${nonselected}) no-repeat center / contain`,
      "-webkit-mask": `url(.${nonselected}) no-repeat center / contain`,
    },
    textPincodeWrap: {
      margin: "0px",
      padding: "0px",
      display: "block",
      position: "relative",
    },
    textPincode: {
      borderRadius: "5px",
      // border: "solid 1px #e4e4e4",
      margin: "23px auto",
      display: "block",
      width: "100%",
      padding: "0 0 0 11px",
      "&& input": {
        fontSize: "20px",
        lineHeight: "13.5px",
        padding: '12px 0 7px',
        color: '#211f20',
        fontWeight: 500
      },
      [theme.breakpoints.down(641)]: {
        margin: "23px auto",
        "&& input": {
          fontSize: "16px",
          lineHeight: "1.2",
        },
      },
      [theme.breakpoints.down(481)]: {
        margin: "12px auto",
        "&& input": {
          fontSize: "14px",
          lineHeight: "1.11",
        },
      },
      [theme.breakpoints.down(421)]: {
        margin: "11px auto",
        "&& input": {
          fontSize: "13.5px",
        },
      },
      [theme.breakpoints.down(401)]: {
        margin: "11px auto",
        "&& input": {
          fontSize: "13px",
        },
      },
      [theme.breakpoints.down(381)]: {
        margin: "8px auto",
        "&& input": {
          fontSize: "12.5px",
          lineHeight: "1.07",
        },
      },
      [theme.breakpoints.down(361)]: {
        "&& input": {
          fontSize: "12px",
        },
      },
      [theme.breakpoints.down(321)]: {
        "&& input": {
          fontSize: "10px",
          lineHeight: "13.5px",
        },
      },
      "& label": {
        paddingLeft: "14px",
        top: '4px',
        transform: 'translate(0, 0) scale(1)',
        fontSize: "20px",
        [theme.breakpoints.down(641)]: {
          fontSize: "16px"
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "14px"
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "13.5px"
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "13px"
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "12.5px"
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "12px"
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "10px"
        },
      },
      "& div": {
        margin: "0px",
        height: "100%",
        width: "100%",
        padding: "0px",
      },
      "&& p": {
        marginTop: "1px",
      },
      "&& div:before": {
        borderBottom: "none",
      },
      "&& div:hover": {
        borderBottom: "none",
      },
    },
    selectListUl: {
      margin: "0px",
      padding: "0px",
      display: "block",
      listStyle: "none",
      position: "absolute",
      top: "100px",
      background: "#fffefe",
      border: "1px solid #ccc",
      borderRadius: 4,
      maxHeight: "200px",
      overflowY: "scroll",
      zIndex: "499",
      width: "93.5%",
      [theme.breakpoints.down("641")]: {
        width: "93.5%",
        top: "88px",
        maxHeight: "200px",
      },
      [theme.breakpoints.down("481")]: {
        width: "93%",
        top: "60px",
        maxHeight: "180px",
      },
      [theme.breakpoints.down("421")]: {
        width: "91%",
        top: "60px",
        maxHeight: "180px",
      },
      [theme.breakpoints.down("401")]: {
        width: "90%",
        top: "60px",
        maxHeight: "180px",
      },
      [theme.breakpoints.down("381")]: {
        width: "90%",
        top: "50px",
        maxHeight: "180px",
      },
      [theme.breakpoints.down("321")]: {
        width: "88%",
        top: "43px",
        maxHeight: "120px",
      },
    },
    less: {
      overflow: "auto",
    },
    selectListLi: {
      width: "100%",
      height: "auto",
      margin: "0px",
      padding: "10px 10px",
      display: "block",
      fontSize: "0.7rem",
      textAlign: "left",
      cursor: "pointer",
    },
    cssLabel: {
      color: "#aaaaaa",
      "&.focused": {
        color: "#aaaaaa",
      },
      "&.shrink": {
        // fontSize: 8,
        // transform: "translate(1px, 12px) scale(0.75)",
        transform: "translate(-2px, -4px) scale(0.75)",
        // [theme.breakpoints.down("481")]: {
        //   transform: "translate(1px, 16px) scale(0.75)",
        // },
        // [theme.breakpoints.down("381")]: {
        //   transform: "translate(1px, 14px) scale(0.75)",
        // },
        // [theme.breakpoints.down("321")]: {
        //   transform: "translate(1px, 18px) scale(0.75)",
        // },
      },
    },
    cssUnderline: {
      "&:after": {
        borderBottom: "none",
      },
      "&&&&:before": {
        borderBottom: "none",
      },
    },
    input: {
      "&:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0 1000px white inset",
      },
    },
    menuItem: {
        fontSize: '18px',
        [theme.breakpoints.down('641')]: {
            fontSize: '16px'
        },
        [theme.breakpoints.down('481')]: {
            fontSize: '14px'
        },
        [theme.breakpoints.down('421')]: {
            fontSize: '13.5px'
        },
        [theme.breakpoints.down('401')]: {
            fontSize: '13px'
        },
        [theme.breakpoints.down('381')]: {
            fontSize: '12.5px'
        },
        [theme.breakpoints.down('361')]: {
            fontSize: '12px'
        },
        [theme.breakpoints.down('321')]: {
            fontSize: '10px'
        }
    },
    selectWrapper:{
      padding:'0px 10px 20px 10px'
    }
  };
};