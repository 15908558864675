import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import queryString from "query-string";
import { url_details } from "./containers/Screens/PV/redux/action";
import { connect } from "react-redux";
import { getStorageItem } from "./common/localStorage";

class SplitRouting extends React.Component {

  render() {
    const { location } = this.props;
    const currentPath = location.pathname;

    // Check if the pathname includes '/pv'
    if (getStorageItem("userType") === "pvUser") {
      return <Redirect to="/pv" />;
    } else {
      return <Redirect to="/login" />;
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    url_details: (data) => dispatch(url_details(data)),
  };
};

// Wrap the component with withRouter to get access to routing props
export default connect(null, mapDispatchToProps)(withRouter(SplitRouting));
